import React from 'react';
import './footer.css'; 

const Footer = () => {
  return (
    <footer className='footer'>
      Minh Buu. This website has been developped in React
    </footer>
  )
}

export default Footer