import React from 'react';
import IntroBusiness from '../components/BusinessExperience/introBusiness';
import BusinessExperience from '../components/BusinessExperience/businessExperience';



const BusinessPage = () => {
  
  return (
    <div className="App">
      <IntroBusiness/>
      <BusinessExperience/>
    </div>

    
    
  )
}

export default BusinessPage