import React from 'react';
import IntroIT from '../components/ItExperience/introIT';
import ItExperience from '../components/ItExperience/itExperience';


const ItExperiencePage = () => {
  
  return (
    <div className="App">
      <IntroIT/>
      <ItExperience/>
    </div>

    
    
  )
}

export default ItExperiencePage